<template>
  <v-card
    color="neutral"
    class="rounded-xl"
  >
    <v-data-table
      :headers="headers"
      :items="projects"
      sort-by="calories"
      class="elevation-1 neutral"
    >
      <template #top>
        <v-toolbar
          flat
          rounded="xl"
          color="neutral"
        >
          <v-toolbar-title v-text="$t('Pages.Company.projects')" />
          <v-divider
            class="mx-4"
            inset
            vertical
          />
          <v-spacer />
          <v-dialog
            v-model="dialog"
            content-class="rounded-xl"
            max-width="500px"
          >
            <v-card
              color="neutral"
              class="rounded-xl"
            >
              <v-card-title>
                <span class="text-h5">Edit permissions</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="4"
                    >
                      <v-checkbox
                        v-model="editedItem.permissions"
                        color="accent"
                        label="Admin"
                        value="Admin"
                      />
                      <v-checkbox
                        v-model="editedItem.permissions"
                        color="accent"
                        label="User"
                        value="User"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer />
                <v-btn
                  text
                  @click="close"
                  v-text="$t('Common.cancel')"
                />
                <v-btn
                  rounded
                  light
                  @click="save"
                  v-text="$t('Common.save')"
                />
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="dialogDelete"
            content-class="rounded-xl"
            max-width="500"
          >
            <CustomDialog
              title-icon="delete"
              :title="$t('Pages.Company.remove_project')"
              :second-title="$t('Pages.Company.remove_project_text')"
              :action-button="{
                class: 'deleteAction',
                id: 'delete-member-confirm-btn',
                text: $t('Common.delete')
              }"
              :close-button="{
                id: 'delete-cancel-btn',
                text: $t('Common.cancel')
              }"
              @close-event="closeDelete"
              @perform-action="deleteItemConfirm"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template #item.actions="{ item }">
        <v-icon
          small
          @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template #no-data>
        <v-btn
          color="primary"
          @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import CustomDialog from '@/common/CustomDialog.vue'
export default {
  name: 'CompanyProjects',
  components: {
    CustomDialog
  },
  data: () => ({
    dialog: false,
    dialogDelete: false,
    projects: [],
    editedIndex: -1,
    editedItem: {
      permissions: []
    },
    defaultItem: {
      permissions: ['admin']
    }
  }),
  computed: {
    headers () {
      return [
        {
          text: this.$t('Pages.Company.project_name'),
          align: 'start',
          value: 'name'
        },
        { text: this.$t('Pages.Company.date_modified'), value: 'date', sortable: false },
        { text: '', value: 'actions', sortable: false }
      ]
    }
  },
  watch: {
    dialog (val) {
      val || this.close()
    },
    dialogDelete (val) {
      val || this.closeDelete()
    }
  },
  created () {
    this.initialize()
  },

  methods: {
    initialize () {
      this.projects = [
        {
          name: 'Projekt Abaddon',
          date: '2020-04-12'
        },
        {
          name: 'Projekt Enchantress',
          date: '2021-07-05'
        },
        {
          name: 'Projekt Juggernaut',
          date: '2020-05-30'
        }
      ]
    },

    editItem (item) {
      this.editedIndex = this.projects.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.projects.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm () {
      this.projects.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save () {
      if (this.editedIndex > -1) {
        Object.assign(this.projects[this.editedIndex], this.editedItem)
      } else {
        this.projects.push(this.editedItem)
      }
      this.close()
    }
  }
}
</script>
